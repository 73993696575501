@import "./variables.scss";

* {
  font-family: $heldenflix-font;
}

#awardSlide {
  background-image: $bg07;
  background-position: center;
  background-size: cover;
  padding-bottom: 5%;
  padding-top: 5%;
  min-height: 100vh;
  margin: 0px;
}

#awardSlide > header {
  width: 90%;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.awards-grid {
  display: grid;
  height: 100%;
  width: 90%;
  column-gap: 10px;
  row-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(150px, 1fr));
}

.award {
  background-color: $heldenflix-rood-border;
  background-image: url("../assets/icons/award-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  align-self: stretch;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px;

  .award__icon {
    background-color: $heldenflix-rood-border;
    // border-radius: 50%;
    width: 20%;
    max-height: 100%;
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      position: relative;
      max-height: 80px;

      align-self: center;
      // overflow: hidden;
      // position: relative;
      // top: -10px;
      // left: -10px;
      filter: invert(1);
    }
  }

  .award__information {
    padding-left: 10px;
    color: black;

    h5 {
      color: black;
      font-size: 0.8em;
      margin: 0px;
    }

    h6 {
      color: white;
      font-size: 1.5em;
      font-weight: 600;
      margin-bottom: 20px;
      * {
        color: $heldenflix-rood;
      }
    }
    width: 60%;
  }
}

form > label {
  color: black;
}

input[type="text"] {
  border: none;
  border: 1px solid $heldenflix-rood;
  border-radius: 5px;
  padding: 4px;
  color: black;
  width: 100%;

  &:focus {
    border: 2px solid $heldenflix-rood-border;
  }
}

.beste {
  color: black;
}
