@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");

//COLORS
$heldenflix-rood: #ff382c;
$heldenflix-rood-border: #c52630;
$heldenflix-green: #1ec400;
$heldenflix-blue: #2c75ff;
$heldenflix-manual-text: rgb(155, 155, 155);

$heldenflix-font: "Space Grotesk", sans-serif;
$heldenflix-bc: rgba(0, 0, 0, 0.803);
$heldenflix-dark-grey: rgba(255, 255, 255, 0.75);
$heldenflix-light-grey: #d7d7d7;

$breakpoint-large: 1200px;
$breakpoint-small: 768px;
$breakpoint-v-large: 900px;

// BACKGROUNDS
$bg01: linear-gradient(0deg, rgb(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)),
  url("../assets/imgs/02_prenten/bg01_ehc.jpg");
$bg02: linear-gradient(0deg, rgb(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)),
  url("../assets/imgs/02_prenten/bg02_ehc.jpg");
$bg03: linear-gradient(0deg, rgb(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)),
  url("../assets/imgs/02_prenten/bg03_ehc.jpg");
$bg04: linear-gradient(0deg, rgb(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)),
  url("../assets/imgs/02_prenten/bg04_ehc.jpg");
$bg05: linear-gradient(0deg, rgb(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)),
  url("../assets/imgs/02_prenten/bg05_ehc.jpg");
$bg06: linear-gradient(0deg, rgb(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)),
  url("../assets/imgs/02_prenten/bg06_ehc.jpg");
$bg07: linear-gradient(0deg, rgb(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)),
  url("../assets/imgs/02_prenten/bg07_ehc.jpg");

// ICONS
$bg-icon-flipcard: url("../assets/icons/Mask\ Group\ 1@2x.png");
