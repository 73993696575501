@import "./variables.scss";

#inspirationSlide {
  background-image: $bg07;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  padding-bottom: 5%;
  padding-top: 5%;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#inspirationSlide > header {
  width: 90%;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h3 {
    font-style: normal;
    font-size: 2em;
  }
}

.inspiration-masonry {
  width: 90%;
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
}
.inspiration-masonry_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
  &:first-of-type {
    padding-left: 0px;
  }
}

/* Style your items */
.inspiration-masonry .type {
  margin-bottom: 30px;

  img {
    max-width: 100%;
    border-radius: 5px 5px 5px 0px;
  }
  div {
    border-radius: 0px 0px 5px 5px;
    color: white;
    text-align: center;
    width: fit-content;
    font-size: 14px;
    padding: 3px 10px;
  }
}

.personage {
  img {
    border: $heldenflix-rood solid 1px;
  }
  div {
    background-color: $heldenflix-rood;
  }
}

.locatie {
  img {
    border: $heldenflix-green solid 1px;
  }
  div {
    background-color: $heldenflix-green;
  }
}
.attribuut {
  img {
    border: $heldenflix-blue solid 1px;
    background-color: $heldenflix-dark-grey;
  }
  div {
    background-color: $heldenflix-blue;
  }
}

/*
.inspiration-grid {
  display: grid;
  height: 100%;
  width: 90%;
  column-gap: 10px;
  row-gap: 10px;
  grid-template-columns: repeat(4, minmax(1fr, 300px));
  grid-template-rows: repeat(auto-fit, minmax(150px, 1fr));

  img {
    max-width: 100%;
  }
}
*/
