@import "./variables.scss";

* {
  font-family: "Space Grotesk";
}

.button--large {
  background-color: $heldenflix-rood;
  color: white;
  border-radius: 50px;
  padding-left: 20px;

  &:hover {
    color: white;
    text-decoration: none;
    background-color: $heldenflix-rood-border;
  }

  .button-next {
    border: none;
    background-color: white;
    border-radius: 50px;
    height: 50px;
    width: 50px;
    margin: 5px 5px 5px 15px;
  }
  img {
    width: 20px;
    fill: $heldenflix-rood;
  }
}

.stapButton {
  background-color: $heldenflix-rood;
  border-radius: 3px;
  border: $heldenflix-rood-border;
  display: inline-block;
  font-size: 12px;
  padding: 5px;
  font-weight: bold;
  position: relative;
  top: -5px;
  margin-right: 10px;
}
.verderButton,
.terugButton {
  text-align: center;

  &:hover {
    text-decoration: none;
  }
  @media screen and (max-width: $breakpoint-large) {
    display: none;
  }
  @media screen and (max-height: $breakpoint-v-large) {
    display: none;
  }
}
.verderButton {
  justify-self: flex-end;
}

.externalButton {
  position: relative;
  border-radius: 50%;
  top: 2px;
  display: inline-block;
  width: 22px;
  height: 22px;
  background-color: $heldenflix-rood;
  text-decoration: none;
  margin: 0px 5px;
  line-height: 1.7em;
  font-size: 15px;
  text-align: center;

  a:hover {
    text-decoration: none;
  }

  /*
  img {
    position: absolute;
    left: 4px;
    top: 4px;
    width: 10px;
    height: 10px;
    align-self: center;
  }*/
}

.downloadButton {
  // border: $heldenflix-rood-border solid 1px;
  position: relative;
  top: 4px;
  border-radius: 50%;
  display: inline-block;
  width: 22px;
  height: 22px;
  background-color: $heldenflix-rood;
  text-decoration: none;
  margin: 0px 5px;
  text-align: center;
  line-height: 1.9em;
  font-size: 14px;

  a:hover {
    text-decoration: none;
  }
}

.downloadButton:hover,
.externalButton:hover {
  background-color: $heldenflix-rood-border;
  cursor: pointer;
  color: $heldenflix-light-grey;
}
.icon-external-link {
  font-weight: 300;
  font-size: 0.8em;
}

// Mobile navbars bootstrap

.btn-group .dropdown-menu-right {
  margin-top: 10px;
  background-color: #333333 !important;
  border: $heldenflix-light-grey solid 1px !important;
  .dropdown-item {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: white !important;
    padding: 5px 35px;
    &:hover,
    &:focus {
      background-color: $heldenflix-rood-border !important;
      color: white !important;
    }
  }
  .disabled {
    color: $heldenflix-rood !important;
  }
  .divider {
    margin-left: 40px;
  }
}
