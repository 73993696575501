.tools-header {
  width: 100vw;
  padding: 3vh 5vw;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background-color: none ;

  position: fixed;
  z-index: 1;

  @media screen and (max-width: $breakpoint-small) {
    background-color: black ;
  }

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

#flipcardSlide > h3 {
  font-size: 2rem;
  width: 90%;
  text-align: left;
}

#awardSlide > h3 {
  text-align: left;
  font-size: 2rem;
  width: 90%;
}

#inspirationSlide > h3 {
  font-size: 2rem;
  width: 90%;
}
