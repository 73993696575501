@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap");
@import "./variables.scss";
@import "~bootstrap/scss/bootstrap";
@import "./flipcards.scss";
@import "./buttons.scss";
@import "./styles.css";

@import "./oproeppagina.scss";
@import "./awards.scss";
@import "./inspiration.scss";
@import "./breakpoint.scss";
@import "./tools.scss";
@import "./solutions.scss";
* {
  box-sizing: border-box;
  color: white;
  font-family: "Space Grotesk";
}

.html,
body {
  scroll-behavior: smooth;
  // height: 100vh;
  font-family: $heldenflix-font;
  position: relative;

  h3 {
    font-family: $heldenflix-font;
    font-size: 1.5em;
  }
  h4 {
    font-size: 24px;
  }
}

.link-reset {
  color: white;
  display: block;
  text-align: center;
  &:hover {
    text-decoration: none;
    color: white;
  }
}

h1,
h2 {
  font-family: "Bebas Neue";
  color: $heldenflix-rood;

  h2 {
    font-size: 3em;
  }

  h1 {
    font-size: 5em;
    margin: 0;
  }
}

section {
  background-size: cover;
  background-position: center;
  width: 100%;
}

.manualPage {
  height: min-content;
  align-self: center;

  @media screen and (min-width: $breakpoint-large) {
    min-height: 100vh !important;
  }
  @media screen and (max-width: $breakpoint-large) {
    min-height: 0 !important;
  }
  @media screen and (max-height: $breakpoint-v-large) {
    height: min-content !important;
    min-height: 100vh;
  }
  @media screen and (max-width: $breakpoint-small) {
    height: min-content !important;
    min-height: 0;
  }
}

#landingPage {
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
  background: $bg03;
  background-size: cover;
  background-position: top;
}

.manual {
  background-color: $heldenflix-dark-grey;
  width: 100%;
  margin: 0 auto;
  position: relative;

  @media screen and (min-width: $breakpoint-large) {
    > nav {
      max-width: 400px;
      position: fixed;
      display: flex;
      height: 100vh;
      flex-direction: column;
      justify-content: center;
      z-index: +1;
      align-self: start;

      img {
        position: absolute;
        top: 3vh;
      }
    }
  }
  @media screen and (max-width: $breakpoint-large) {
    width: 100vw;
    position: relative;
    > nav {
      display: none;
      position: sticky;
      margin: 30px 40px;
      z-index: +1;
      width: 95vw;
    }
  }
}

#manual__navbar {
  margin-left: 2rem;
  margin-top: 0px;
  padding-top: 2rem;
}

#manual__navbar a.manual__navbar-active {
  font-size: 2em;
  color: white;
  font-weight: bold;
}

#manual__navbar a {
  text-decoration: none;
  display: block;
  padding: 0.125rem 0;
  color: $heldenflix-dark-grey;

  transition: all 50ms ease-in-out;

  &:hover,
  &:focus {
    color: white;
    text-decoration: none;
  }
}

.divider {
  margin-bottom: 15px;
  padding-bottom: 15px;
  width: 50px;
  border-bottom: $heldenflix-dark-grey solid 1px;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

.manual__pages {
  // scrolling
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  position: relative;

  @media screen and (min-width: $breakpoint-large) {
    height: 100vh;
    scroll-snap-type: y mandatory !important;
    scroll-behavior: smooth !important;
  }
  @media screen and (max-width: $breakpoint-large) {
    background: $bg02;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    scroll-behavior: auto;
    scroll-snap-type: none;
  }
  @media screen and (max-height: $breakpoint-v-large) {
    scroll-behavior: auto;
    scroll-snap-type: none;
  }
  width: 100vw;
}

.manualPage__information {
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (min-width: $breakpoint-large) {
    align-self: center;
    max-width: 900px;
  }
  @media screen and (max-width: $breakpoint-large) {
    align-self: center;
    max-width: 80vw;
  }
  @media screen and (max-width: $breakpoint-small) {
    align-self: center;
    max-width: 90vw;
  }
}

.manualPage__steps {
  background-color: rgba(0, 0, 0, 0.6);
  border: rgba(255, 255, 255, 0.105) solid 1px;
  padding: 25px 50px;
  &:not(:first-child),
  &:not(:last-child) {
    margin: 5px 0px;
  }

  width: 100%;

  @media screen and (max-width: $breakpoint-large) {
    align-self: right;
    width: 100%;
  }

  @media screen and (max-width: $breakpoint-small),
    screen and (max-height: $breakpoint-v-large) {
    padding: 15px 20px;
  }
}

.slide {
  display: flex;
  flex-direction: column;
  // justify-content: space-evenly;
  align-items: center;
  background-size: cover;
  overflow-y: scroll;
  background-position: center;
}

#landingPage__wrapper {
  display: flex;
  flex-direction: column;
  // justify-content: space-evenly;
  // margin: 30px auto;
  align-items: center;
}

#landingPage__wrapper > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

#landingPage__explanation {
  margin: 10% 15px;
  background-color: $heldenflix-bc;
  border: rgba(255, 255, 255, 0.105) solid 1px;
  max-width: 600px;
  // margin: 15px;
  padding: 50px;
}

#landingPage p {
  color: $heldenflix-manual-text;
}
#callforactionSlide {
  background: $bg02;
}

#flipcardSlide {
  background: $bg03;
}
#awardSlide {
  background: $bg04;
}

.nav__room {
  width: 350px;
}

.manualPage {
  scroll-snap-align: center;

  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 20px;

  @media screen and (min-width: $breakpoint-large) {
    margin: 0px 25px 0px 45px;
  }
}

a {
  text-decoration: none;
}

ul.manualPage__navbar {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  max-width: 350px;

  li {
    margin: 5px;
  }

  a {
    color: white;
  }

  .manualPage__navbar-active {
    font-size: 1.8em;
    line-height: 0em;
    transition-timing-function: ease-in-out;
    transition-duration: 0.6s;
  }
}

.logo {
  width: 200px;
}
.logo-klein {
  width: 150px;
}

.logo-groot {
  width: 400px;
  margin-top: 30px;

  @media screen and (max-width: 600px) {
    width: 80%;
  }
}
.manual section {
  width: 100%;

  // max-height: 100vh;
  ul,
  ol {
    list-style-type: none;
    margin-bottom: 1em;
    color: $heldenflix-manual-text;
    font-weight: 100;
  }
  li::before {
    content: "•";
    font-weight: 800;
    color: $heldenflix-rood;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
  li {
    margin-bottom: 5px;
    line-height: 1.4em;
    &:last-of-type {
      margin-bottom: 0px;
    }
    margin-left: 2em;
    color: $heldenflix-manual-text;
  }
  .manualPage__title {
    color: white;
    font-family: $heldenflix-font;
    font-size: 2.5em;
    padding-top: 20px;
    padding-bottom: 0px;
    margin-bottom: 20px;
    line-height: 2.8rem;
    align-self: flex-start;

    @media screen and (max-width: $breakpoint-large) {
      font-size: 2.2em;
    }
  }
  .title-hidden {
    // @media screen and (min-width: $breakpoint-large) {
    //   display: none;
    // }
  }

  p {
    margin-bottom: 20px;
    color: $heldenflix-manual-text;
    font-weight: 100;
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
  ul + p {
    margin-bottom: 20px;
  }
}

.text-link {
  color: $heldenflix-rood;
  text-decoration: underline;

  &:hover {
    color: $heldenflix-rood-border;
    cursor: pointer;
  }
}

.text-emphasis {
  color: white;
}
.text-emphasis-rood {
  color: $heldenflix-rood;
}

.underline {
  text-decoration: underline;
}

#slogan {
  text-align: center;
  margin-top: 5vh;
  @media screen and (max-width: $breakpoint-small) {
    font-size: 1rem;
  }
}
h4 + ul,
ol {
  margin: 5px 0px;
  li {
    margin-bottom: 0px;
  }
}

.manual .navbar-mobile {
  width: 100%;
  display: flex;
  position: sticky;
  margin: 0 auto;
  background-color: black;

  @media screen and (min-width: $breakpoint-large) {
    display: none;
  }

  @media screen and (max-width: $breakpoint-large) {
  }
}

.tussenkop {
  color: $heldenflix-rood;
  align-self: flex-start;
  font-size: 1.2em;

  @media screen and (max-width: $breakpoint-large) {
    font-size: 1em;
  }
}

.tussenkop-groot {
  margin-bottom: -25px;
}

#navbarNavAltMarkup .navbar-nav {
  background-color: none;

  border: $heldenflix-dark-grey solid 1px;
  border-radius: 5px;
  align-self: flex-end;
  max-width: 250px;
  padding: 5px 15px;

  .nav-item {
    background-color: black;
  }
}

.icon-sprokkelboek {
   height: 22px;
   margin-left: 4px;
   margin-right: 4px;
 }

