#oproeppagina {
  background-image: $bg07;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  overflow-y: scroll;
  position: relative;
  min-height: 100vh;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
}

#oproeppagina__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 5vh auto;

  div:first-of-type {
    text-align: center;
  }
}

#call-to-action {
  text-align: left;
  display: flex;
  border: $heldenflix-rood solid 1px;
  border-radius: 5px;
  margin: 5vh 0px;
  @media screen and (max-width: 750px) {
    flex-direction: column;
    width: 95vw;
  }

  div {
    padding: 30px;
    height: 350px;
    width: 350px;

    align-items: center;
    text-align: center;
    p {
      font-size: 1.1rem;
    }

    @media screen and (max-width: 750px) {
      width: 100%;
      padding: 20px;
    }
  }

  div:first-of-type {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  div:last-of-type {
    background-color: $heldenflix-rood;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      height: 250px;
    }
  }

  ul,
  ol {
    list-style-type: none;
    margin-bottom: 1em;
    color: $heldenflix-manual-text;
    font-weight: 100;
  }
  li::before {
    content: "•";
    font-weight: 800;
    color: $heldenflix-rood;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
  li {
    // font-size: 1.3rem;
    margin-bottom: 20px;
    line-height: 1.3rem;
    &:last-of-type {
      margin-bottom: 0px;
    }
    margin-left: 1em;
    color: $heldenflix-manual-text;
  }
}

footer {
  width: 100%;
  padding: 2vw 5vw;

  @media screen and (min-height: 850px) {
    position: absolute;
    bottom: 0;
  }

  display: flex !important;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 700px) {
    margin-top: 10px;
    // background-color: $heldenflix-bc;
  }

  img {
    max-height: 50px;
    margin: 10px;
    height: 10vw;
  }

  .logoOetang {
  }
  .logoBib {
  }
  .logoSponsor {
  }
}

.reviews {
  position: absolute;
  top: 5vh;

  @media screen and (max-width: 1500px) {
    display: none;
  }
}

.reviews-left {
  left: 5vw;
}

.reviews-right {
  right: 5vw;
}

.review {
  padding: 15px 10px 0px 10px;
  // border: $heldenflix-bc solid 1px;
  border-radius: 5px;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  p {
    margin-bottom: 5px;
    color: $heldenflix-manual-text;
  }

  .review-comment {
    font-size: 0.9rem;
    text-align: left;
  }

  .review-author {
    align-self: flex-end;
    max-width: 80%;
    font-style: italic;
    font-size: 0.7rem;
  }
}

.icon-star,
.icon-star-o,
.icon-star-half-o {
  color: $heldenflix-rood;
}

#call-to-action--mobile {
  min-height: 100vh;
  background-image: $bg06;
  background-position: left;
  background-size: cover;
  background-attachment: fixed;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  align-items: center;

  div:first-of-type {
    padding-top: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    > h3 {
      font-size: 1.6em;
      margin-top: 0px;
    }
  }

  p {
    color: $heldenflix-manual-text;
  }

  #uitleg {
    background-color: rgba(0, 0, 0, 0.6);
    border: rgba(255, 255, 255, 0.105) solid 1px;
    max-width: 600px;
    width: 95vw;
    margin: 30px;
    padding: 30px;
  }

  // ul,
  // ol {
  //   list-style-type: none;
  //   margin-bottom: 1em;
  //   font-weight: 100;
  // }
  // li {
  //   margin-bottom: 20px;
  //   line-height: 1.3rem;
  //   &:last-of-type {
  //     margin-bottom: 0px;
  //   }
  //   display: flex;
  //   align-items: center;
  //   color: $heldenflix-manual-text;
  // }

  #call-to-action__instructies {
    margin-bottom: 1em;
    li {
      color: white;
      margin-left: 20px;
      margin-bottom: 20px;
      position: relative;

      &::before {
        content: "·";
        color: $heldenflix-rood-border;
        font-size: 3em;
        position: absolute;
        top: -27px;
        left: -20px;

        span {
          display: inline;
        }
      }
    }
  }

  .stapButton {
    min-width: 50px;
    top: -10px;
    margin-right: 20px;
  }
}
