.solution {
  height: 100%;
  min-height: 100vh;
  background-image: $bg06;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;

  > h3 {
    margin-top: 30px;
  }
}

.nicetry {
  height: 100vh;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 100vh;
    max-width: 90vw;
  }
  div > h4 {
    color: $heldenflix-dark-grey;
  }
  div > .logo {
    margin: 5px 0px;
  }
}

#solution-opdracht1b,
#solution-opdracht1a,
#opdracht6 {
  display: flex;
  flex-direction: column;
  align-items: center;

  .video-grid {
    display: flex;
    width: 90%;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    iframe {
      width: 560px;
      height: 315px;
      margin: 5px 10px;
      padding: 0px;

      @media screen and (max-width: 600px) {
        width: 90vw;
        height: 50.625vw;
      }
    }

    .nieuw {
      border: $heldenflix-blue solid 3px;
    }

    .oud {
      border: $heldenflix-rood solid 3px;
    }
  }
}

// Opdracht 7 - Solution
.solution__opdracht7 {
  // position: relative;
  min-height: 100vh;
  width: 1414px;
  background-color: black !important;
  background-image: none;
  display: flex;
  justify-content: left;
  img {
    height: 1000px;
    width: 1414.52px;
    position: absolute;
  }

  .btn-tooltip {
    background-color: $heldenflix-rood;
    position: relative;
    height: 50px !important;
    width: 50px !important;
    border-radius: 50%;
    border: none;

    text-align: left;
    &:focus,
    &:hover {
      background-color: $heldenflix-rood-border;
    }
  }

  #hercules {
    top: 350px;
    left: 1250px;
  }
  #blackPanther {
    top: 450px;
    left: 1050px;
  }
  #spiderman {
    top: 150px;
    left: 950px;
  }
  #greenKnight {
    top: 550px;
    left: 810px;
  }
  #galien {
    top: 220px;
    left: 530px;
  }
  #invisibleWoman {
    top: 420px;
    left: 460px;
  }
  #blancefloerFloris {
    top: 470px;
    left: 300px;
  }
  #zwanenridder {
    top: 470px;
    left: -70px;
  }
  #joker {
    top: 390px;
    left: 0px;
  }
  #storm {
    top: 190px;
    left: 30px;
  }

  .popover > .popover-body {
    background-color: $heldenflix-bc;
    color: white;
  }

  .solution__opdracht7-instruction {
    border: $heldenflix-manual-text solid 1px;
    background-color: $heldenflix-bc;
    padding: 5px;
    max-width: 200px;
    width: 95vw;
    position: fixed;
    top: 10px;
    z-index: 1;

    p {
      margin-bottom: 0px;
    }
  }
}

#opdracht8 {
  background-image: $bg03;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .opdracht8__desktop {
    display: none;
    padding-bottom: 30px;
    img {
      max-width: 400px;
      margin: 30px 15px 0px 15px;
    }
    @media screen and (min-width: 700px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  .opdracht8__mobile {
    .carousel-control-prev-icon {
    }
    .carousel-control-next-icon {
    }

    display: none;
    img {
      max-width: 700px;
    }

    @media screen and (max-width: 700px) {
      display: inline-block;
    }
  }
}

#opdracht2 {
  background-image: $bg05;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  max-width: 100vw;

  img {
    width: 100vw;
    max-width: 750px;
  }
}

#opdracht3 {
  background-image: $bg07;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  max-width: 100vw;
  height: 100vh;

  img {
    width: 100vw;
    max-width: 750px;
  }
}

#opdracht6 {
  background-image: $bg01;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  min-height: 100vh;

  h1 {
    max-width: 90vw;
  }

  .video-grid {
    display: flex;
    max-width: 700px;
    flex-direction: column;

    > div {
      display: flex;
      flex-direction: column;
      margin-bottom: 50px;
    }
  }

  h4 {
    align-self: flex-start;
  }
}

#opdracht10 {
  background-image: $bg04;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  text-align: center;
  max-width: 100vw;
  min-height: 100vh;

  img {
    width: 100vw;
    max-width: 750px;
    margin-bottom: 20px;
  }
}
