@media screen and (min-width: $breakpoint-large) {
  .manual section {
    display: grid;
    grid-template-columns: 350px 1fr;
  }

  #waarom {
    background-image: $bg02;
  }
  #materiaal {
    background-image: $bg03;
  }
  #voorbereiding {
    background-image: $bg04;
  }
  #fase1 {
    background-image: $bg05;
  }
  #fase2 {
    background-image: $bg06;
  }
  #fase3 {
    background-image: $bg07;
  }
 
  #tips {
    background-image: $bg01;
  }
  #feedback {
    background-image: $bg02;
  }
}
