@charset "UTF-8";

@font-face {
  font-family: "untitled-font-1";
  src: url("../assets/fonts/untitled-font-1.eot");
  src: url("../assets/fonts/untitled-font-1.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/untitled-font-1.woff") format("woff"),
    url("../assets/fonts/untitled-font-1.ttf") format("truetype"),
    url("../assets/fonts/untitled-font-1.svg#untitled-font-1") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "untitled-font-1" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "untitled-font-1" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-external-link:before {
  content: "\61";
}
.icon-arrow-right:before {
  content: "\62";
}
.icon-angle-right:before {
  content: "\63";
}
.icon-right-open-big:before {
  content: "\64";
}
.icon-download:before {
  content: "\65";
}
.icon-book-dowload-streamline:before {
  content: "\66";
}
.icon-down-circled:before {
  content: "\67";
}
.icon-link-external:before {
  content: "\68";
}
.icon-arrow-circle-o-down:before {
  content: "\69";
}
.icon-arrow-down:before {
  content: "\6a";
}
.icon-star:before {
  content: "\6b";
}
.icon-star-half-o:before {
  content: "\6c";
}
.icon-star-o:before {
  content: "\6d";
}
