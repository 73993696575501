@import "./variables.scss";

* {
  font-family: $heldenflix-font;
}

#flipcardSlide {
  background-image: $bg05;
  background-position: center;
  background-size: cover;
  padding-bottom: 5%;
  padding-top: 5%;
  min-height: 100vh;
  margin: 0px;
}

#flipcardSlide > header {
  width: 90%;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flipcards-grid {
  display: grid;
  height: 100%;
  width: 90%;
  column-gap: 5px;
  row-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(200px, 1fr));
}

.flip-card {
  align-self: stretch;
  min-width: 300px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:active .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  padding: 10px;
  width: 100%;
  height: 100%;
  border-radius: 5px;

  text-align: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: $heldenflix-rood-border;
  background-image: $bg-icon-flipcard;
  background-size: initial;
  background-position: left;
  background-repeat: no-repeat;
  border-color: $heldenflix-rood-border;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.flip-card-back {
  background-color: black;
  border-color: $heldenflix-rood-border;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.animation {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.flip-card {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;

  /*depth of the elements */
  -webkit-perspective: 800px;
  -moz-perspective: 800px;
  -o-perspective: 800px;
  perspective: 800px;

  /*border: 1px solid #ff0000;*/
  padding-left: 1%;
}

.flip-card-inner {
  width: 99%;
  min-height: 200px;
  cursor: pointer;

  /*transition effects */
  -webkit-transition: -webkit-transform 0.6s;
  -moz-transition: -moz-transform 0.6s;
  -o-transition: -o-transform 0.6s;
  transition: transform 0.6s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.flip-card-inner.flipped {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.flip-card-inner .flip-card-front,
.flip-card-inner .flip-card-back {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  line-height: 60px;
  color: white;
  text-align: center;
  font-size: 4em;
  position: absolute;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  box-shadow: 3px 5px 20px 2px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16),
    0 2px 5px rgba(0, 0, 0, 0.26);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 2px 5px rgba(0, 0, 0, 0.26);
}

.flip-card-inner .flip-card-back {
  width: 100%;
  padding: 3%;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  background: $heldenflix-bc;
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
